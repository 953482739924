// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-case-study-js": () => import("../src/templates/case_study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-solutions-js": () => import("../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */),
  "component---src-templates-product-page-js": () => import("../src/templates/product_page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-legal-js": () => import("../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-company-js": () => import("../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-whitepaper-js": () => import("../src/templates/whitepaper.js" /* webpackChunkName: "component---src-templates-whitepaper-js" */),
  "component---src-templates-blog-post-js": () => import("../src/templates/blog_post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-study-listing-js": () => import("../src/pages/case-study-listing.js" /* webpackChunkName: "component---src-pages-case-study-listing-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pharmaos-js": () => import("../src/pages/pharmaos.js" /* webpackChunkName: "component---src-pages-pharmaos-js" */),
  "component---src-pages-resources-js": () => import("../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-retailos-js": () => import("../src/pages/retailos.js" /* webpackChunkName: "component---src-pages-retailos-js" */)
}

